<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="brandIndex"
  >
    <div
      class="index-wrap"
      v-show="contentView"
    >
      <div class="crumbs">
        <span @click="toBrandLib()">品牌库 ></span>
        <span @click="toDetails">{{ brandName }} ></span>
        <span class="zs">品牌指数</span>
      </div>
      <div class="brand-overview">
        <div class="overview-title">
          <i></i>品牌指数概览
          <em
            class="el-icon-warning"
            @click="showBrandIndex()"
          ></em>
          <div class="w-input-brand-index">
            <el-select
              popper-class="w-block-select-down"
              v-model="regionType"
              placeholder="请选择"
              size="small"
              style="width: 120px;"
              @change="getIndexChartNumFunc"
            >
              <el-option
                v-for="item in regionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <span @click="customizationClick">
            <i class="icon-font icon-zuanshi"></i>定制指数
          </span>
        </div>
        <div class="overview-content">
          <p>赢商品牌指数：品牌指数评价体系是由赢商大数据发布的国内首个关于实体商业品牌商户的专业排行榜，涉及零售、餐饮、文体娱、儿童亲子、生活服务5个大类，分成近40+不同类别进行测评排名。</p>
          <p
            v-for="(item, index) in dataList"
            :key="index"
            v-show="item.score && item.score !== 0"
          >{{ item.title }}</p>
        </div>
        <div class="li-wrap">
          <ul>
            <li
              v-for="(item, index) in dataList"
              :key="index"
              :class="['li' + index]"
              v-show="item.score !== 0"
            >
              <p class="mark">{{ item.score }}分</p>
              <p class="index">{{ item.name }}</p>
              <p class="dashes"></p>
              <p class="text">高于 {{ item.percent }}% 同品类品牌</p>
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </div>
      <div
        class="analysis-index"
        v-if="flag1"
      >
        <div class="analysis-title">
          <i></i> 人气指数分析
        </div>
        <div class="chart">
          <div class="title">与竞品&同品类市场人气指数对比</div>
          <div>
            <div
              id="bar1"
              :style="{ width: '470px', height: '380px' }"
              class="table-wrap"
            ></div>
          </div>
          <div class="tips">
            <span></span> 人气指数
            <i></i> 同品类均值
          </div>
        </div>
        <div class="table">
          <div class="title">当前品牌在同品类中所处位置（取其所在位置的前后5个品牌）</div>
          <div class="content">
            <div class="content-head">
              <ul>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
              </ul>
            </div>
            <ul class="main">
              <li
                v-for="(item, index) in heatArr"
                :key="index"
              >
                <span>{{ item.brandName }}</span>
                <i>{{ item.score }}</i>
              </li>
              <li
                class="noBcak"
                @click="toBrandRank(9006, industryIds, industryNames)"
              >
                <span>查看该指数完整排名</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="analysis-index">
        <div class="analysis-title">关店预警指数分析</div>
        <div class="chart">
          <div class="title">与竞品&同品类关店预警指数对比</div>
          <div>
            <div id="bar2" :style="{width:'470px',height:'380px'}" class="table-wrap"></div>
          </div>
          <div class="tips">
            <span></span> 关店预警指数 <i></i> 同品类均值
          </div>
        </div>
        <div class="table">
          <div class="title">当前品牌在同品类中所处位置（取其所在位置的前后5个品牌）</div>
          <div class="content">
            <div class="content-head">
              <ul>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
              </ul>
            </div>
            <ul class="main">
              <li v-for="(item, index) in steadyArr"
                :key="index">
                <span>{{item.brandName}}</span>
                <i>{{item.score}}</i>
              </li>
              <li class="noBcak" @click="toBrandRank(9007, industryIds, industryNames)">
                <span>查看该指数完整排名</span>
              </li>
            </ul>
          </div>
        </div>
      </div>-->
      <div
        class="analysis-index"
        v-if="flag2"
      >
        <div class="analysis-title">
          <i></i>潜力指数分析
        </div>
        <div class="chart">
          <div class="title">与竞品&同品类潜力指数对比</div>
          <div>
            <div
              id="bar3"
              :style="{ width: '470px', height: '380px' }"
              class="table-wrap"
            ></div>
          </div>
          <div class="tips">
            <span></span> 潜力指数
            <i></i> 同品类均值
          </div>
        </div>
        <div class="table">
          <div class="title">当前品牌在同品类中所处位置（取其所在位置的前后5个品牌）</div>
          <div class="content">
            <div class="content-head">
              <ul>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
              </ul>
            </div>
            <ul class="main">
              <li
                v-for="(item, index) in potentialArr"
                :key="index"
              >
                <span>{{ item.brandName }}</span>
                <i>{{ item.score }}</i>
              </li>
              <li
                class="noBcak"
                @click="toBrandRank(9008, industryIds, industryNames)"
              >
                <span>查看该指数完整排名</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="analysis-index"
        v-if="flag3"
      >
        <div class="analysis-title">
          <i></i>综合实力指数分析
        </div>
        <div class="chart">
          <div class="title">与竞品&同品类综合实力指数对比</div>
          <div>
            <div
              id="bar4"
              :style="{ width: '470px', height: '380px' }"
              class="table-wrap"
            ></div>
          </div>
          <div class="tips">
            <span></span> 综合实力指数
            <i></i> 同品类均值
          </div>
        </div>
        <div class="table">
          <div class="title">当前品牌在同品类中所处位置（取其所在位置的前后5个品牌）</div>
          <div class="content">
            <div class="content-head">
              <ul>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
              </ul>
            </div>
            <ul class="main">
              <li
                v-for="(item, index) in matureArr"
                :key="index"
              >
                <span>{{ item.brandName }}</span>
                <i>{{ item.score }}</i>
              </li>
              <li
                class="noBcak"
                @click="toBrandRank(9001, industryIds, industryNames)"
              >
                <span>查看该指数完整排名</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="analysis-index"
        v-if="flag4"
      >
        <div class="analysis-title">
          <i></i>租金贡献指数分析
        </div>
        <div class="chart">
          <div class="title">与竞品&同品类租金贡献指数对比</div>
          <div>
            <div
              id="bar5"
              :style="{ width: '470px', height: '380px' }"
              class="table-wrap"
            ></div>
          </div>
          <div class="tips">
            <span></span> 租金贡献指数
            <i></i> 同品类均值
          </div>
        </div>
        <div class="table">
          <div class="title">当前品牌在同品类中所处位置（取其所在位置的前后5个品牌）</div>
          <div class="content">
            <div class="content-head">
              <ul>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
                <li>
                  <span>品牌名称</span>
                  <i>指数得分</i>
                </li>
              </ul>
            </div>
            <ul class="main">
              <li
                v-for="(item, index) in steadyArr"
                :key="index"
              >
                <span>{{ item.brandName }}</span>
                <i>{{ item.score }}</i>
              </li>
              <li
                class="noBcak"
                @click="toBrandRank(9010, industryIds, industryNames)"
              >
                <span>查看该指数完整排名</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="customization-mask"
      v-show="isCustomization"
    >
      <div class="customization-wrap">
        <i
          class="el-icon-close"
          @click="hideCustomization"
        ></i>
        <h3>定制指数</h3>
        <p class="customization-p1">在实际应用中，不同场景下对所需品牌的筛选偏好不尽一致，赢商大数据可根据客户认知结合品牌实际表现数据定制性简历算法模型参数得出与实际需求一致的品牌，如需定制指数可联系赢商进行咨询。</p>
        <p class="customization-p2">
          <img
            src="@/assets/images/erweima.png"
            alt
          />
        </p>
        <p class="customization-p3">立即扫描咨询</p>
      </div>
    </div>
    <div
      class="jurisdiction-wrap mt15"
      v-if="isHasJurisdiction"
    >
      <img
        src="@/assets/images/Jurisdiction.png"
        alt
      />
      <p>暂无权限访问此页，如需开通权限，</p>
      <p>请联系管理员在“管理中心-账号管理”开通</p>
    </div>
    <brand-index
      v-if="tipsHide"
      @closeBrandIndex="closeBrandIndex"
    ></brand-index>
  </div>
</template>

<script>
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import api from '@/api'
import brandIndex from '@/components/brandIndexTips.vue'
export default {
  data () {
    return {
      loading: true,
      isHasJurisdiction: false,
      contentView: false,
      dataList: [],
      heatArr: [],
      steadyArr: [],
      potentialArr: [],
      matureArr: [],
      operateBar: [],
      brandName: '',
      industryIds: '',
      industryNames: '',
      isCustomization: false,
      flag1: true,
      flag2: true,
      flag3: true,
      flag4: true,
      tipsHide: false,
      regionList: [],
      regionType: 1,
      oldRegionType: 1
    }
  },
  components: {
    brandIndex
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.axios.post(api.getRegionList, { brandId: this.$route.query.id })
      .then((res) => {
        this.regionList = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.item
          }
        })
        this.getIndexChartNumFunc()
      })
  },
  methods: {
    getIndexChartNumFunc () {
      this.loading = true
      this.axios.post(api.getIndexChartNum, { brandId: this.$route.query.id, regionType: this.regionType })
        .then((res) => {
          if (res.data.code === 0) {
            if (res.data.data.nums.length === 0) {
              this.$message({
                type: 'warning',
                message: '品牌在该区域的指数正在建设中...'
              })
              this.regionType = this.oldRegionType
              this.loading = false
              return false
            }
            this.dataList = res.data.data.nums.map((item, index) => {
              if (item.name === '人气指数') {
                item.title = '人气指数含义：反映品牌受消费者关注的程度。指数越高，代表品牌在同品类中能吸引更多的受众关注。'
              } else if (item.name === '潜力指数') {
                item.title = '潜力指数含义：反映近5年创立的新品牌发展潜力程度。指数越高，代表品牌在同品类的新品牌中，发展势头更高。'
              } else if (item.name === '综合实力指数') {
                item.title = '综合实力指数含义：反映品牌综合实力，指数越高，代表品牌在同品类中行业优势地位越大。同等条件下能给场所端带来更高形象、更多流量、更稳定经营及更明显的羊群效应。'
              } else if (item.name === '租金贡献指数') {
                item.title = '租金贡献指数含义：反映品牌的租金贡献能力，指数越高，代表相同条件下（同品类，同面积），该品牌整体上能给商铺贡献更高租金或租金溢价。'
              }
              return item
            })
            this.brandName = res.data.data.brandName
            this.industryIds = res.data.data.industryIds
            this.industryNames = res.data.data.industryNames
            // 人气指数
            const barNumber1 = []
            const barName1 = []
            const barIndustry1 = []
            this.heatArr = res.data.data.charts[0].near10 || []
            for (let i = 0; i < res.data.data.charts[0].competitors.length; i++) {
              if ((this.$route.query.id - 0) === res.data.data.charts[0].competitors[i].brandId) {
                if (res.data.data.charts[0].competitors[i].score === 0) {
                  this.flag1 = false
                }
              }
              barNumber1.push(res.data.data.charts[0].competitors[i].score)
              barName1.push(res.data.data.charts[0].competitors[i].brandName)
              barIndustry1.push(res.data.data.charts[0].industryAvg)
            }
            if (this.flag1) {
              this.heatBar(barNumber1, barName1, barIndustry1)
            }
            // let barNumber2 = []
            // let barName2 = []
            // let barIndustry2 = []
            // this.steadyArr = res.data.data.charts[1].near10
            // for (let i = 0; i < res.data.data.charts[1].competitors.length; i++) {
            //   barNumber2.push(res.data.data.charts[1].competitors[i].score)
            //   barName2.push(res.data.data.charts[1].competitors[i].brandName)
            //   barIndustry2.push(res.data.data.charts[1].industryAvg)
            // }
            // this.steadyBar(barNumber2, barName2, barIndustry2)
            // 潜力指数
            const barNumber3 = []
            const barName3 = []
            const barIndustry3 = []
            this.potentialArr = res.data.data.charts[1].near10 || []
            for (let i = 0; i < res.data.data.charts[1].competitors.length; i++) {
              if ((this.$route.query.id - 0) === res.data.data.charts[1].competitors[i].brandId) {
                if (res.data.data.charts[1].competitors[i].score === 0) {
                  this.flag2 = false
                }
              }
              barNumber3.push(res.data.data.charts[1].competitors[i].score)
              barName3.push(res.data.data.charts[1].competitors[i].brandName)
              barIndustry3.push(res.data.data.charts[1].industryAvg)
            }
            if (this.flag2) {
              this.potentialBar(barNumber3, barName3, barIndustry3)
            }
            // 综合实力指数
            const barNumber4 = []
            const barName4 = []
            const barIndustry4 = []
            this.matureArr = res.data.data.charts[2].near10 || []
            for (let i = 0; i < res.data.data.charts[2].competitors.length; i++) {
              if ((this.$route.query.id - 0) === res.data.data.charts[2].competitors[i].brandId) {
                if (res.data.data.charts[2].competitors[i].score === 0) {
                  this.flag3 = false
                }
              }
              barNumber4.push(res.data.data.charts[2].competitors[i].score)
              barName4.push(res.data.data.charts[2].competitors[i].brandName)
              barIndustry4.push(res.data.data.charts[2].industryAvg)
            }
            if (this.flag3) {
              this.matureBar(barNumber4, barName4, barIndustry4)
            }
            const barNumber5 = []
            const barName5 = []
            const barIndustry5 = []
            this.steadyArr = res.data.data.charts[3].near10 || []
            for (let i = 0; i < res.data.data.charts[3].competitors.length; i++) {
              if ((this.$route.query.id - 0) === res.data.data.charts[3].competitors[i].brandId) {
                if (res.data.data.charts[3].competitors[i].score === 0) {
                  this.flag4 = false
                }
              }
              barNumber5.push(res.data.data.charts[3].competitors[i].score)
              barName5.push(res.data.data.charts[3].competitors[i].brandName)
              barIndustry5.push(res.data.data.charts[3].industryAvg)
            }
            if (this.flag4) {
              this.operateBarBar(barNumber5, barName5, barIndustry5)
            }
            this.oldRegionType = this.regionType
            this.isHasJurisdiction = false
            this.contentView = true
          } else {
            this.loading = false
            this.isHasJurisdiction = true
            this.contentView = false
          }
        })
    },
    showBrandIndex () {
      this.tipsHide = true
    },
    closeBrandIndex () {
      document.body.style.overflowY = 'auto'
      this.tipsHide = false
    },
    customizationClick () {
      this.isCustomization = true
      document.body.style.overflowY = 'hidden'
    },
    hideCustomization () {
      this.isCustomization = false
      document.body.style.overflowY = 'auto'
    },
    heatBar (barNumber1, barName1, barIndustry1) {
      const myChart = echarts.init(document.getElementById('bar1'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b}:\n{c} 均值分数: ' + barIndustry1[0]
        },
        grid: {
          top: '15%',
          right: '10%',
          left: '15%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName1,
          axisLabel: {
            margin: 20,
            rotate: 10,
            color: '#fff',
            textStyle: {
              fontSize: 10
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: 10,
          axisLabel: {
            formatter: '{value}',
            color: '#fff',
            textStyle: {
              fontSize: 14
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber1,
          barWidth: '40%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#32E687'
                // 0% 处的颜色
              }, {
                offset: 1,
                color: '#32E687'
                // 100% 处的颜色
              }], false)
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: true,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: barIndustry1,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#FFA134',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 50
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    steadyBar (barNumber2, barName2, barIndustry2) {
      const myChart = echarts.init(document.getElementById('bar2'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b}:\n{c} 均值分数: ' + barIndustry2[0]
        },
        grid: {
          top: '15%',
          right: '10%',
          left: '15%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName2,
          axisLabel: {
            margin: 20,
            rotate: 10,
            color: '#fff',
            textStyle: {
              fontSize: 10
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: 10,
          axisLabel: {
            formatter: '{value}',
            color: '#fff',
            textStyle: {
              fontSize: 14
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber2,
          barWidth: '40%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#32E687'
                // 0% 处的颜色
              }, {
                offset: 1,
                color: '#32E687'
                // 100% 处的颜色
              }], false)
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: true,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: barIndustry2,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#FFA134',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 50
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    potentialBar (barNumber3, barName3, barIndustry3) {
      const myChart = echarts.init(document.getElementById('bar3'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b}:\n{c} 均值分数: ' + barIndustry3[0]
        },
        grid: {
          top: '15%',
          right: '10%',
          left: '15%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName3,
          axisLabel: {
            margin: 20,
            rotate: 10,
            color: '#fff',
            textStyle: {
              fontSize: 10
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: 10,
          axisLabel: {
            formatter: '{value}',
            color: '#fff',
            textStyle: {
              fontSize: 14
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber3,
          barWidth: '40%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#32E687'
                // 0% 处的颜色
              }, {
                offset: 1,
                color: '#32E687'
                // 100% 处的颜色
              }], false)
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: true,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: barIndustry3,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#FFA134',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 50
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    matureBar (barNumber4, barName4, barIndustry4) {
      const myChart = echarts.init(document.getElementById('bar4'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b}:\n{c} 均值分数: ' + barIndustry4[0]
        },
        grid: {
          top: '15%',
          right: '10%',
          left: '15%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName4,
          axisLabel: {
            margin: 20,
            rotate: 10,
            color: '#fff',
            textStyle: {
              fontSize: 10
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: 10,
          axisLabel: {
            formatter: '{value}',
            color: '#fff',
            textStyle: {
              fontSize: 14
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber4,
          barWidth: '40%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#32E687'
                // 0% 处的颜色
              }, {
                offset: 1,
                color: '#32E687'
                // 100% 处的颜色
              }], false)
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: true,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: barIndustry4,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#FFA134',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 50
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    operateBarBar (barNumber5, barName5, barIndustry5) {
      console.log(barNumber5, barName5, barIndustry5)
      const myChart = echarts.init(document.getElementById('bar5'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b}:\n{c} 均值分数: ' + barIndustry5[0]
        },
        grid: {
          top: '15%',
          right: '10%',
          left: '15%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName5,
          axisLabel: {
            margin: 20,
            rotate: 10,
            color: '#fff',
            textStyle: {
              fontSize: 10
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: 10,
          axisLabel: {
            formatter: '{value}',
            color: '#fff',
            textStyle: {
              fontSize: 14
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber5,
          barWidth: '40%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#32E687'
                // 0% 处的颜色
              }, {
                offset: 1,
                color: '#32E687'
                // 100% 处的颜色
              }], false)
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: true,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: barIndustry5,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#FFA134',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 50
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    toBrandLib () {
      this.$router.push({
        path: '/SelfBrandList',
        query: {
          source: this.$route.query.source
        }
      })
    },
    toDetails () {
      this.$router.push({
        path: '/brandDetail',
        query: {
          id: this.$route.query.id,
          source: this.$route.query.source,
          questionState: this.$route.query.questionState,
          gbid: this.$route.query.gbid
        }
      })
    },
    toBrandRank (orderId, industryIds, industryNames) {
      const SelfBrandList = this.$router.resolve({
        path: '/selfBrandList',
        query: {
          orderId: orderId,
          industryIds: industryIds,
          industryNames: encodeURIComponent(industryNames)
        }
      })
      window.open(SelfBrandList.href, '_blank')
    },
    goScroll (index) {
      this.$el.querySelector(index).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },
    goBackTop () {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-wrap
  width 1280px
  min-height 600px
  margin 0px auto
  position relative
  font-size 14px
  color #fff
  padding-bottom 30px
  .brand-overview
    // margin-top 30px
    background rgba(39, 41, 48, 1)
    padding 0 30px
    padding-top 32px
    padding-bottom 38px
    .overview-title
      position relative
      font-size 16px
      // border-left 2px solid #ffa134
      padding-left 10px
      margin-bottom 30px
      .el-icon-warning
        cursor pointer
      &>i
        position absolute
        left 0
        top 3px
        width 2px
        height 18px
        background #FFA134
      .w-input-brand-index
        position absolute
        right 70px
        top -5px
      span
        position absolute
        right 0
        top 0
        color #FFA134
        font-size 14px
        cursor pointer
        i
          margin-right 8px
          vertical-align initial
    .overview-content
      font-size 13px
      color #fff
      background rgba(255, 255, 255, 0.1)
      padding 12px 6px 13px 14px
      p
        line-height 26px
        letter-spacing 2.3px
    .li-wrap
      margin-top 24px
      ul
        display flex
        justify-content center
        li
          text-align center
          padding-top 35px
          width 285px
          height 159px
          margin 0 24px
          .mark
            font-size 44px
            font-weight 800
          .index
            font-size 16px
            margin-top 6px
          .dashes
            width 200px
            margin 0 auto
            border 1px dashed rgba(217, 217, 217, 0.5)
            margin-top 18px
            margin-bottom 11px
          .text
            font-size 14px
        .li0
          background url('~@/assets/images/brandDetail/bg-1.png') no-repeat
        .li1
          background url('~@/assets/images/brandDetail/bg-2.png') no-repeat
        .li2
          background url('~@/assets/images/brandDetail/bg-3.png') no-repeat
        .li3
          background url('~@/assets/images/brandDetail/bg-4.png') no-repeat
          background-size 100%
          border-radius 4px
        .li4
          background url('~@/assets/images/brandDetail/bg-5.png') no-repeat
.analysis-index
  margin-top 16px
  background #272930
  padding 35px 40px
  height 550px
  .analysis-title
    position relative
    font-size 18px
    // border-left 2px solid #ffa134
    padding-left 10px
    margin-bottom 30px
    i
      position absolute
      left 0
      top 4px
      width 2px
      height 18px
      background #FFA134
  .chart
    float left
    margin 0 15px
    .title
      font-size 16px
  .table
    float left
    margin-left 40px
    .title
      font-size 16px
    .content
      width 644px
      margin-top 20px
      .name
        width 100%
        height 50px
      .main
        width 100%
        height 300px
        writing-mode vertical-lr
        li
          float left
          width 322px
          height 50px
          line-height 50px
          writing-mode horizontal-tb
          &:nth-child(2n)
            background-color rgba(255, 255, 255, 0.1)
          &.active
            background #EB8917
            color #fff
          span
            display inline-block
            width 60%
            margin-left 10px
            margin-right 10px
            font-size 16px
            white-space nowrap
          i
            font-style normal
            font-size 16px
.noBcak
  background none !important
  text-align center
  span
    text-decoration underline
    cursor pointer
.tips
  text-align center
  font-size 14px
  margin-top 10px
  span
    display inline-block
    width 12px
    height 12px
    background #32E687
  i
    margin-left 20px
    display inline-block
    width 27px
    height 1px
    background #FFA134
    vertical-align middle
.content-head
  li
    float left
    height 50px
    line-height 50px
    width 50%
    background-color rgba(255, 255, 255, 0.32)
    span
      display inline-block
      width 60%
      margin-left 10px
      margin-right 10px
      font-size 16px
    i
      font-style normal
      font-size 16px
.crumbs
  height 40px
  line-height 40px
  .zs
    color rgba(255, 255, 255, 0.5)
  span
    cursor pointer
.jurisdiction-wrap
  color #ffffff
  text-align center
  min-height 500px
  padding-top 150px
  background #272930
  img
    margin-bottom 20px
  p
    font-size 14px
    line-height 18px
.customization-mask
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  background rgba(0, 0, 0, 0.5)
  .customization-wrap
    position absolute
    left 50%
    margin-left -325px
    top 150px
    width 650px
    height 410px
    background #ffffff
    border-radius 6px
    padding 40px 60px
    box-sizing border-box
    .el-icon-close
      position absolute
      right 20px
      top 15px
      color #D9D9D9
      cursor pointer
    h3
      text-align center
      font-weight bold
      font-size 28px
      color #333333
      margin-bottom 21px
    .customization-p1
      font-size 14px
      color #666
      line-height 22px
    .customization-p3
      text-align center
      color #EB8917
      margin-top 18px
    .customization-p2
      text-align center
      margin 20px 0
</style>
